import Button from "@/components/Common/Buttons/Button/Button";
import Input from "@/components/Common/Inputs/Input/Input";
import FileInput from "@/components/Common/Inputs/FileInput/FileInput";
import TextArea from "@/components/Common/Inputs/TextArea/TextArea";
import Dropdown from "@/components/Common/Inputs/Dropdown/Dropdown";
import CheckBox from "@/components/Common/Inputs/CheckBox/CheckBox";
import YesNoInput from "@/components/Common/Inputs/YesNoInput/YesNoInput";
import MakePremium from "@/components/Components/MakePremium/MakePremium/";
import PremiumAdSelection from "@/components/Components/PremiumAdSelection/PremiumAdSelection/";
import AdminVendorListProduct from "@/components/Components/AdminVendorListProduct/AdminVendorListProduct";
import CreditWallet from "@/components/Components/CreditWallet/CreditWallet/";
import AdUpdate from "@/components/Components/AdUpdate/AdUpdate/";
import Pagination from "@/components/Components/Pagination/Pagination";
import UserTypeMobile from "@/components/Header/HeaderMobile/UserTypeMobile/UserTypeMobile";
import UserNavMobile from "@/components/Header/HeaderMobile/UserNavMobile/UserNavMobile";
import Modal from "@/components/Modal/Modal";
import PreLoader from "@/components/Common/PreLoader.vue";
import PagePreloader from "@/components/Components/PagePreloader/PagePreloader.vue";


export default {
    name: 'AdminVendorOrders',
    components: {
        PagePreloader,
        PreLoader,
        Modal,

        Button,
        CheckBox,
        MakePremium,
        AdUpdate,
        PremiumAdSelection,
        CreditWallet,
        AdminVendorListProduct,
        TextArea,
        Dropdown,
        Input,
        UserTypeMobile,
        UserNavMobile,
        YesNoInput,
        FileInput,
        Pagination
    },
    data() {
        return {
            isLoading: false,
            filterBoxActive: false,
            activeInactiveSwitch: 'active',
            sortingOptions: [
                {
                    title: 'Цена възходяща',
                    value: 'price_ascending',
                },
                {
                    title: 'Цена низходяща',
                    value: 'price_descending',
                },
                {
                    title: 'Дата на добавяне',
                    value: 'create_date',
                },
            ],
            selectedOption: {
                title: 'Цена възходяща',
                value: 'price_ascending',
            },
            products: [],
            totalProducts: null,
            currentPage: 1,
            offset: 0,
            limit: 10,
        }
    },
    watch: {
        '$route'() {
            this.loadProducts();
        }
    },
    methods: {
        toggleFilterBox() {
            this.filterBoxActive = !this.filterBoxActive
        },
        selectOption(option) {
            this.toggleFilterBox();
            this.selectedOption = option;
        },
        async handleItemDeleted(productId) {
            if (confirm(this.$t('product-delete-confirm-message'))) {
                try {
                    await this.$store.dispatch('product/deleteProduct', productId)
                    await this.$store.dispatch('utils/openNotificationModal', {
                        type: 'success', message: this.$t('product-successfully-deleted'),
                    });
                } catch (err) {
                    await this.$store.dispatch('utils/openNotificationModal', {
                        type: 'error',
                        message: this.$t('error-try-again')
                    });
                }
                await this.loadProducts();
            }
        },
        async loadProducts() {
            try {
                this.currentPage = 1;
                if (this.$route.query.hasOwnProperty('page')) {
                    this.offset = (this.$route.query.page - 1) * this.limit;
                    this.currentPage = parseInt(this.$route.query.page);
                }

                this.isLoading = true;
                // const page = this.$route.query.page ?? 1;
                // this.offset = page <= 1 ? 0 : (page - 1) * this.limit;
                const result = await this.$store.dispatch("product/getVendorProducts", {
                    offset: this.offset,
                    limit: this.limit
                });
                this.products = result.data;
                this.totalProducts = result.total;
                this.offset = result.offset;
                this.isLoading = false;
            } catch (err) {
                this.isLoading = false;
                console.log('Error', err)
            }
        },
        async handlePageChange(page) {
            this.$router.push({path: this.$route.path, query: {page: page}});
        },
        async uploadFile(event) {
            await this.$store.dispatch('utils/openNotificationModal', {type: 'loading'});
            await this.$store.dispatch('utils/closeModal', 'productImport');
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append('file', file, file.name);
            try {
                await this.$store.dispatch('product/importProducts', {file: formData});
                await this.$store.dispatch('utils/closeNotificationModal', {type: 'loading'});
                this.$store.dispatch("utils/openNotificationModal", {
                    message: this.$t('product-import-was-initiated-successfully'),
                    type: 'success'
                });
            } catch (err) {
                await this.$store.dispatch('utils/openNotificationModal', {
                    type: 'error', message: err.message
                });
            }
        },
    },
    async mounted() {
        if (this.$store.state.user.info.type !== 'vendor') {
            this.$router.push({name: 'NotFoundPage'})
        }
        await this.loadProducts()
    },
}
